import D3Funnel from 'd3-funnel'

class Funnel extends React.Component {
  componentDidMount() {
    const props = this.props
    const data = MC.asArray(MC.getFieldParamValue(props.data.param, 'data'))
    this.update(data)
  }
  componentWillReceiveProps(nextProps) {
    const data = MC.asArray(MC.getFieldParamValue(nextProps.data.param, 'data'))
    if (data) {
      this.update(data)
    }
  }
  update(fieldData) {
    fieldData = MC.asArray(fieldData)
    var funnel = this
    if (!fieldData) {
      return
    }
    var node = ReactDOM.findDOMNode(funnel)
    if (!MC.isVisible(node)) {
      return
    }
    var data = fieldData.map(datum => {
      const label = datum['@label']
      const value = datum["@value"]
      const backgroundColor = datum["@backgroundColor"]
      return {label, value, backgroundColor}
    })
    const options = {
        block: {
            dynamicSlope: true,
            minHeight: 15,
        },
    }

    const chart = new D3Funnel(node)
    chart.draw(data, options)
  }
  render() {
    return <div />
  }
}

MC.registerReactRomponent('funnel', Funnel)